@import './AppVariables.scss';
// @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
body{
    font-family: 'Nunito', sans-serif !important;
    position: relative;
    overflow-x: hidden;
}
html{
    position: relative;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    overflow-x: hidden;
}
.bg-secondary{
    background-color: $primary !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}
/* .logo{
    width:250px;
} */
.navbar{
    box-shadow: none !important;
    padding-top: 33px;
    padding-bottom: 32px;
}
.bg-transparent{
    background-color: transparent !important;
}
.ml-auto{
    margin-left: auto !important;
}
.navbar-light .navbar-nav .show>.nav-link, .navbar-light .navbar-nav .nav-link.active {
    color: $white;
    background:$secondary;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
}
.navbar-light .navbar-nav .nav-link {
    color: $white;
    font-size: 16px;
    margin-left: 5px;
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus{
    color: $white;
}
.carousel-full-section{
    background: transparent;
}
.carousel-item-slide{
    min-height: 580px;
    padding-top:50px;
}
.banne-title{
    font-size: 42px;
    // font-family: 'Lora', serif !important;
    font-family: 'Nunito', sans-serif !important;
    color: $secondary;
    font-weight: bold;
}
.banne-title span{
    color: $subHeading;
}
.bg-header{
    background-image: url('../assets/Header_Bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding-top:120px;
    padding-bottom: 30px;
    background-position: bottom;
}
.carousel-item-slide-1{
    background-image: url('../assets/BannerImage.png');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
}
.carousel-item-slide-2{
    background-image: url('../assets/BannerImage-v2.png');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
}
.banner-content-block{
    margin-top:50px;
    margin-left: 50px;
    position: absolute;
    left: 35%;
    top: 15%;
}
.carousel-indicators li{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: transparent;
    opacity: 1;
    border:3px solid $white !important;
    background-color: transparent !important;
    margin-right: 10px;
}
.carousel-indicators .active{
    background-color: $secondary !important;
}
.bg-header .carousel{
    margin-bottom: -100px;
}
.what-hear-say{
    padding-top:180px;
    padding-bottom: 80px;
}
.hear-title{
    font-size: 32px;
    color: $secondary;
    margin-bottom: 30px;
    // font-family: 'Lora', serif !important;
    font-family: 'Nunito', sans-serif !important;
    font-weight: bold;
}
.what-hear-content p{
    color: $black;
    font-size: 16px;
    text-align: justify;
}
.what-hear-section-block p{
    color: $black;
    font-size: 16px;
    text-align: justify;
}
.mt-5{
    margin-top: 50px !important;
}
.mb-5{
    margin-bottom: 50px !important;
}
.mt-7{
    margin-top: 70px !important;
}
.mb-7{
    margin-bottom: 70px !important;
}
.what-hear-instruction-title{
    color: $primary;
    font-size: 24px;
    // font-family: 'Lora', serif !important;
    font-family: 'Nunito', sans-serif !important;
    font-weight: 600;
}
.features{
    background: transparent linear-gradient( 90deg, $lightBlue 0%, $grey 100%) 0% 0% no-repeat padding-box;
    padding-top: 80px;
    padding-bottom: 80px;
}
.section-title{
    font-size: 32px;
    color: $secondary;
    // font-family: 'Lora', serif !important;
    font-family: 'Nunito', sans-serif !important;
    font-weight: bold;
}
.features p{
    color: $subHeading;
    font-size: 16px;
}
.feature-section-block{
    display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;      /* TWEENER - IE 10 */
    display: -webkit-flex;     /* NEW - Chrome */
    display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
    background: #ffff;
    border-radius: 10px;
    box-shadow: 0 20px 25px -5px #9e9e9e2e, 0 10px 10px -5px #9e9e9e2e !important;
    padding: 18px;
    border: 1px solid #181819;
}
.feature-section-block .icon img{
    width: 50px;
}
.feature-section-block .text-content{
    align-self: center;
    padding-left: 20px;
}
.feature-block{
    border:1px solid $subHeading;
    background: $white;
    padding-top:40px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
    border-radius: 10px;
    box-shadow: 0 20px 25px -5px #9e9e9e2e, 0 10px 10px -5px #9e9e9e2e !important;
    text-align: center;
}
.feature-title{
    font-size: 16px;
    color: $black;
    font-weight: 400;
    margin-bottom: 0px;
}
@media (min-width:700px) {
    .feature-title{
        
    }
}
.pricing-plan{
    background: transparent linear-gradient(90deg, #bbe5f6 0%, #d4cfe8 100%) 0% 0% no-repeat padding-box;
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative;
}
.pricing-plan-content p{
    font-size: 16px;
    color: $black;
    margin-top:20px;
}
.pricing-plan-block{
    padding:30px;
    border-radius: 10px;
    text-align: center;
    margin-left: 30px;
    cursor: pointer;
    background: #ffff;
    box-shadow: 0 20px 25px -5px #9e9e9e2e, 0 10px 10px -5px #9e9e9e2e !important;
    /* padding: 18px; */
    border: 1px solid #181819;
}
.pricing-plan-block ul{
    list-style: none;
}
.plan-details{
    padding-top:20px;
    padding-bottom: 20px;
    margin-bottom: 30px;
}
.plan-title{
    color: $black;
    font-size: 28px;
    font-weight: bold;
}
.plan-price{
    color: $primary;
    margin-top: 20px;
    font-weight: bold;
    margin-bottom: 0px;
    font-size: $size60;
}
.plan-details span{
    font-size: 0.8rem;
    color: $black;
    font-weight: 500;
}
.pricing-plan-block ul li{
    font-size: 16px;
    color: $black;
    margin-bottom: 20px;
    font-weight: 500;
}
.pricing-plan-block p{
    font-size: 16px;
    color: $black;
    margin-bottom: 0px;
}
.button{
    margin-top:30px;
}
.button .btn-primary{
    background: transparent;
    color: $secondary;
    padding-left: 40px;
    padding-right: 40px;
    text-transform: inherit;
    font-size: 16px;
    border:1px solid $Heading2;
    border-radius: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    transition: transform .425s cubic-bezier(.895,.03,.685,.22);
    font-weight: 500;
}
.pricing-plan-block:hover{
    background: transparent linear-gradient(
        180deg, #1faae1 0%, #1faae1 50%, #bc1c8c 100%);
        border: 1px solid #1faae1 !important;
}
.pricing-plan-block:hover .plan-price, .pricing-plan-block:hover .plan-title, .pricing-plan-block:hover .plan-details span, .pricing-plan-block:hover .register-note{
    color: $white !important;
}
.pricing-plan-block:hover .plan-details{
    /* border-bottom: 3px solid $white; */
}
.pricing-plan-block:hover p{
    font-size: 16px;
    color: $white;
}
.pricing-plan-block:hover ul li{
    color: $white;
}
.pricing-plan-block:hover .button .btn-primary{
    background: $white;
    border:none !important;
    color: $primary;
}
.self-center{
    align-self: center;
}
.schedule-demo{
    padding-top: 50px;
    padding-bottom: 50px;
    background: linear-gradient(90deg,$blue 0,$blue 40%,$blue 0%,$blue 60%, $secondary);
position: relative;
}
.schedule-book-section{
    z-index: 999;
}
@media only screen and (min-width:700px) and (max-width:1000px) {
    .feature-title{
        min-height: 150px;
    }
    .ipad-hide{
        display: none;
    }
    .ipad-w-100{
        width: 100% !important;
    }
    .pricing-plan-block{
        margin-left: 0px;
    }
    .ipad-w-60{
        width: 60% !important;
    }
    .ipad-w-40{
        width:40% !important;
    }
}
@media only screen and (min-width:1000px) and (max-width:4000px) {
    .schedule-demo .image img{
        position: absolute;
        top:0px;
        left: 0px;
        right: 0px;
        width: 65% !important;
        bottom: 0px;
        height: 100%;
        display: none;
    }
    .schedule-demo::before{
        content:'';
        background-image: url('../assets/HearSay-ScheduleDemo-Image-03-01.png');
        position: absolute;
        left:0px;
        right:0px;
        top:0px;
        bottom: 0px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left center;
    } 
}
.schedule-title{
    color: $Heading2;
    font-size: 32px;
    // font-family: 'Lora', serif !important;
    font-family: 'Nunito', sans-serif !important;
}
.schedule-demo p {
    font-size: 16px;
    color: $white;
    margin-top:10px;
}
.schedule-demo .form-group{
    margin-bottom: 0px;
}
.schedule-demo .form-control{
    background: $white;
    padding:8px;
    border-radius: 10px;
    box-shadow: none !important;
    box-shadow: none !important;
    outline: none !important;
    margin-top:5px;
}
.schedule-demo .form-group label{
    color: $white;
}
.btn-secondary{
    background: $white;
    color: $secondary;
    padding-left: 40px;
    padding-right: 40px;
    text-transform: inherit;
    font-size: 16px;
    border-radius: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
}
.footer{
    background: $lightGrey;
    padding-top:80px;
}
.footer-title{
    font-size: 1.5rem;
    color: $primary;
    font-weight: bold;
}
.footer-content-link, .footer-content-link a{
    color: $primary;
    font-size:16px;
    font-weight: 500;
}
.social-links{
    list-style-type: none;
    display: flex;
}
.social-links li img{
    width: 32px;
    margin-right: 10px;
}
.copyright{
    border-top: 1px solid #d3d3d3;
    padding-top: 20px;
    padding-bottom: 20px;
}
.copyRight-title{
    font-size: 16px;
    color: $primary;
}
/** Mobile Responsive **/
@media (max-width:700px) {
    .logo{
        width:180px;
    }
    .navbar-light .navbar-toggler{
        background: $lightGrey;
    }
    .navbar-nav{
        background: $primary;
        padding-top: 30px;
    }
    .carousel-item-slide-1 {
        min-height: auto !important;
        border-radius: 10px;
        padding-bottom: 50px;
        background-size: 100% 100%;
    }
    .pricing-plan-block ul{
        padding-left: 0px;
    }
    .carousel-item-slide-2{
        min-height: auto !important;
        border-radius: 10px;
        padding-bottom: 50px;
        background-size: 100% 100%;
    }
    .banner-content-block {
        margin-top: 30px;
        left: 0px;
        top: 0px;
        position: relative;
        background: $lightGrey;
        padding: 10px;
        margin: 20px;
    }
    .banne-title {
        font-size: 1.8rem;
        color: #bc1c8c;
    }
    .what-hear-say {
        padding-top: 140px;
    }
    .hear-title {
        font-size: 2rem;
    }
    .what-hear-instruction-title {
        color: #403999;
        font-size: 1.5rem;
    }
    .mobile-col-sep{
        margin-top:30px;
    }
    .section-title {
        font-size: 2rem;
    }
    .features{
        padding-top:50px;
        padding-bottom: 50px;
    }
    .feature-title {
        font-size: 1rem;
    }
    .pricing-plan {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .pricing-plan-block{
        margin-left: 0px;
    }
    .schedule-demo {
        padding-top: 50px;
        padding-bottom: 50px;
        background: linear-gradient(
    182deg, #f4f7fc 0, #f4f7fc 0%, #1faae1 0%, #1faae1 80%, #bc1c8c);
    }

}
.nav-link:hover{
    background: $secondary;
    padding-left: 20px !important;
    padding-right: 20px !important;
}
.nav-link{
    padding-left: 20px !important;
    padding-right: 20px !important;
    border-radius: 10px;
}
.registered-instructor-block{
   /*  border: 1px solid #18181982;
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
    */
    background: $white;
    border-radius: 10px;
    box-shadow: 0 20px 25px -5px #9e9e9e2e, 0 10px 10px -5px #9e9e9e2e !important;
    padding: 25px;
}
.registered-instructor-block .instructor-title{
    color: #403999;
    margin-top: 30px;
    font-size: 22px;
    // font-family: 'Lora', serif !important;
    font-family: 'Nunito', sans-serif !important;
    font-weight: 500;
}
.registered-instructor-block .button .btn-primary{
    padding-left: 20px !important;
    padding-right: 20px !important;
}
.registered-instructor-block .designation{
    color: $black;
    font-size: 16px;
    display: inline;
}
.registered-instructor-block .image-block{
    text-align: center;
    text-align: -webkit-center;
}
.registered-instructor-block .button{
    margin-top:20px !important;
}
.registered-instructor-block .image-block img{
    width:130px !important;
    border-radius: 50%;
}
.feature-block p{
    min-height: 130px;
}
.owl-next, .owl-prev {
    font-size: 62px!important;
    position: absolute;
    opacity: 1 !important;
}
.owl-next {
    right: -30px;
    top: 22%;
}
.owl-prev {
    left: -30px;
    top: 22%;
}
.registeredInstructors{
    padding-bottom: 50px;
    padding-top: 50px;
    background: $primary;
}
.registeredInstructors .section-title{
    color: $white !important;
}
.instructor-text{
    color: $white !important;
}
.owl-theme .owl-nav [class*='owl-']:hover{
    color: $secondary !important;
    background: transparent !important;
}
@media (max-width:700px) {
    .owl-prev{
        left: 0px !important;
        top:15% !important;
    }
    .owl-next{
        right: 0px !important;
        top:15% !important;
    }
    .hear-say-extra-features .owl-carousel .owl-item{padding-left:30px;padding-right:30px}
    .owl-carousel .owl-item {
        padding-left: 0px;
        padding-right: 0px;
    }
    .registered-instructor-block .image-block{
        text-align: left !important;
    }
    .feature-block p{
        min-height: auto !important;
    }
}
@media only screen and (min-width:1000px) and (max-width:1350px) {
    .feature-block p {
        min-height: 150px !important;
    }
    .carousel-item-slide-1 {
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 10px;
        /* background-size: 100%; */
        /* background-color: white; */
        background-position: center;
    }
    .carousel-item-slide-2 {
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 10px;
        /* background-size: 100%; */
        /* background-color: white; */
        background-position: center;
    }
    .registered-instructor-block .button .btn-primary {
        padding-left: 15px !important;
        padding-right: 15px !important;
        font-size: 1rem;
    }
    .schedule-demo::before{
        content:'';
        background-image: url('../assets/HearSay-ScheduleDemo-Image-03-01.png');
        position: absolute;
        left:0px;
        right:0px;
        top:0px;
        bottom: 0px;
        background-repeat: no-repeat;
        background-size: 92% !important;
        background-position: left center;
    } 
}
.invisible-feedback{
    width: auto;
    margin-top: 0.25rem;
    font-size: .875rem;
    color: #f93154;
}
.invalid{
    border-color: #f93154;
}
.payment-details-block{
    background: $white;
    /* padding: 30px; */
    border: 1px solid #d3d3d3;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    z-index: 999;
    padding-right: 0px;
    padding-left: 0px;
}
.payment-details-block .title-head{
    border-bottom: 2px solid #d1d1d1;
    padding-left: 30px;
    padding-top: 20px;
}
.payment-details-block .title-head .hear-title{ 
    font-size: 24px;
    margin-bottom: 20px;
}
.payment-process-content{
    padding: 30px;
}
.payment-process-content h6{
    font-size: 18px;
    border-bottom: 1px dashed #a6a6a9;
    padding-bottom: 20px;
}
.payment-process-content .form-group label{
    font-size: 16px;
}
.payment-process-content .form-group label sup{
    color: red !important;
}
.payment-process-content form{
    margin-top:30px;
}
.payment-process-content form .form-group{
    margin-bottom: 13px;
}
.payment-process-content form .form-control{
    border: 1.5px solid #9c9cbd;
    outline: none !important;
    box-shadow: none !important;
    margin-top: 5px;
    padding:10px;
}
.payment-details-block .btn-secondary{
    background: #403999 !important;
    color: #fff !important;
}
.bg-billing-summary{
    background: #ebebf7;
}
.bg-billing-summary .title-head{
    border-bottom: 2px solid #d1d1d1;
    padding-top: 20px;
    padding-bottom: 15px;
}
.bg-billing-summary .plan-description{
    padding-top:30px;
    padding-bottom: 30px;
}
.bg-billing-summary tr td strong{
    color: $Heading2;
}
.bg-billing-summary table{
    border-bottom: 1px dashed #d1d1d1;
    border-collapse: inherit;
    padding-top:15px;
    padding-bottom: 15px;
}
.bg-billing-summary .title{
    text-align: right;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #4a449e;
    font-size: 16px;
}
.bg-billing-summary .title strong{
    font-size: 20px;
}
.bg-billing-summary .btn{
    background: #c40693;
    color: #fff;
}
.payment-process label{
    font-size: 16px;
}
.btn-start-trial{
    background: #ebebf7;
    text-transform: capitalize;
    color: #01003f;
    font-size: 16px;
}
.payment-process{
    background-position: right;
}
@media (max-width:700px) {
    .payment-process .container{
        margin-left: 15px;
        margin-right: 15px;
        width: auto;
    }
    .payment-details-block {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 0px;
    }
    .form-check {
        display: -webkit-box;
    }
    .payment-details-block .btn-secondary {
       padding-left: 10px;
        padding-right: 10px;
    }
    .bg-billing-summary .btn{
        padding-left: 10px;
        padding-right: 10px;
    }
    .mobile-space-top{
        margin-top:30px;
    }
    .mobile-hide{
        display: none;
    }
    .hightlight-text{
        margin-left: 0px;
    }
}
p{
    font-weight: 500;
}
.owl-nav img{
    width:50px;
}
@media only screen and (min-width:900px) and (max-width:1300px) {
    .navbar-light .navbar-nav .nav-link{
        font-size: 14px !important;
    }
    .banner-content-block{
        margin-right: 10px;
    }
    .banne-title {
        font-size: 38px;
    }
    .registered-instructor-block .button .btn-primary {
        padding-left: 5px !important;
        padding-right: 5px !important;
        font-size: 0.85rem;
    }
    .plan-price {
        font-size: 42px !important;
    }
    .pricing-plan-block {
        padding: 15px;
       margin-left: 10px;
    }
    .button .btn-primary {
        padding-left: 30px;
        padding-right: 30px;
        text-transform: inherit;
        font-size: 15px;
    }
}
/** Extra Features **/
.hear-say-extra-features{
    padding-top:80px;
    padding-bottom: 80px;
}
/** Page Breadcrumb **/
.customer-support{
    background-position: center !important;
    padding-bottom: 0px !important;
}
.bg-page-breadcrumb{
    padding-top:30px;
    padding-bottom: 30px;
    background: $lightGrey;
    text-align: center;
}
.page-breadcrumb-title{
    font-size: 32px;
    color:$subHeading;
    margin-bottom: 0px;
    font-family: 'Nunito', sans-serif !important;
    font-weight: bold;
}
/** Support **/
.inside-page-content{
    padding-top:60px;
    padding-bottom: 60px;
    background: $white;
}
.inside-page-content p{
    color: $black;
    font-size: 16px;
}
.inside-page-content .page-content-block .page-title{
    color: $secondary;
    font-size: 28px;
    margin-bottom: 20px;
    font-family: 'Nunito', sans-serif !important;
    font-weight: bold;
}
.inner-page-list-block{
    padding-left: 20px;
    padding-top: 20px;
}
.inner-page-list-block h6{
    color: $black;
    font-size: 18px;
    font-weight: bold;
}
.inner-page-list-block ul li{
    color: $black !important;
    font-size: 16px !important;
    font-weight: 500;
}
.inside-page-content ul li {
    color: $black !important;
    font-size: 16px !important;
    font-weight: 500;
}
.padding-left-none{
    padding-left: 0px !important;
}
.navbar-brand .nav-link{
    padding-left: 0px !important;
    padding-right: 0px !important;
    background: transparent !important;
}
.hightlight-text{
    color:$secondary;
    font-size: 24px;
    margin-left: 20px;
}
.register-note{
    color:$primary;
    font-size: 16px;
}


.price-convertor .plan-title {
    font-size: 20px;
}
.price-convertor .flex{
    display: flex;
    justify-content: center;
    margin-top:20px;
}
.price-convertor .form-check{
    margin-right: 10px;
}
.form-check-input[type="radio"]:checked::after {
    border-color: $secondary;
    background-color: $secondary;
}
.price-convertor .plan-price{
    font-size: 32px !important;
    border: 1px solid #d3d3d3;
    padding: 10px;
    margin-top: 20px;
    border-radius: 10px;
}
.price-convertor{
    margin-bottom: 0px;
    padding-bottom: 0px;
}
.pricing-plan-block{
    min-height: 430px;
}