$primary:#403999;
$secondary:#bc1c8c;
$subHeading:#181819;
$Heading2:#01003f;
$white:#fff;
$lightGrey:#f4f7fc;
$lightBlue:#bbe5f6;
$grey:#d4cfe8;
$blue:#1faae1;
$black:#000000;
$size60:60px;